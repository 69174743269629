import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/home',
    component: lazy(() => import('../pages/Home')),
  },
];

export default routes;
