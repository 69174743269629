import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/subcategory",
    component: lazy(() => import("../pages/SubCategory")),
  },
  {
    exact: true,
    path: "/subcategory/add",
    component: lazy(() => import("../pages/SubCategory/create-update")),
  },
  {
    exact: true,
    path: "/subcategory/add/:id",
    component: lazy(() => import("../pages/SubCategory/create-update")),
  },
];

export default routes;
