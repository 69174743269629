import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/category",
    component: lazy(() => import("../pages/Category")),
  },
  {
    exact: true,
    path: "/category/add",
    component: lazy(() => import("../pages/Category/create-update")),
  },
  {
    exact: true,
    path: "/category/add/:id",
    component: lazy(() => import("../pages/Category/create-update")),
  },
];

export default routes;
