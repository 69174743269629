import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/bank',
    component: lazy(() => import('../pages/Bank')),
  },
  {
    exact: true,
    path: '/bank/add',
    component: lazy(() => import('../pages/Bank/create')),
  },
  {
    exact: true,
    path: '/bank/edit/:id',
    component: lazy(() => import('../pages/Bank/edit')),
  },
];

export default routes;
