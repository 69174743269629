// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/page_mapping",
    component: lazy(() => import("../../pages/UserAccess/PageMapping/index")),
  },
  {
    exact: true,
    path: "/page_mapping/add",
    component: lazy(() => import("../../pages/UserAccess/PageMapping/create")),
  },
  {
    exact: true,
    path: "/page_mapping/edit/:id",
    component: lazy(() => import("../../pages/UserAccess/PageMapping/edit")),
  },
];

export default routes;
