import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/delivery-point',
    component: lazy(() => import('../pages/Delivery_Point')),
  },
  {
    exact: true,
    path: '/delivery-point/add',
    component: lazy(() => import('../pages/Delivery_Point/create-edit')),
  },
  {
    exact: true,
    path: '/delivery-point/edit/:id',
    component: lazy(() => import('../pages/Delivery_Point/create-edit')),
  },
];

export default routes;
