import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/product_variant",
    component: lazy(() => import("../pages/ProductVariant/viewall")),
  },
  {
    exact: true,
    path: "/product_variant/:productId",
    component: lazy(() => import("../pages/ProductVariant")),
  },
  {
    exact: true,
    path: "/product_variant/add/:productId",
    component: lazy(() => import("../pages/ProductVariant/create")),
  },
  {
    exact: true,
    path: "/product_variant/edit/:id",
    component: lazy(() => import("../pages/ProductVariant/edit")),
  },
  {
    exact: true,
    path: "/product_activation",
    component: lazy(() => import("../pages/ProductVariant/Product Activation")),
  },
];

export default routes;
