import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import store from './store';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import '../src/assets/css/style.css';
import { authCheck } from './store/actions/user';


store.dispatch(authCheck())
ReactDOM.render(
  <Provider store={store}>
       <Routes />
    </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
