import * as ActionTypes from '../action-types';

const initialState = {
    createAlert: false,
    updateAlert: false,
};

const NotificationReducer = (state = initialState, { type, payload = null }) => {
   
  switch (type) {
    case ActionTypes.SHOW_CREATE_ALERT:
            return show_create_alert(state,payload);
        case ActionTypes.SHOW_UPDATE_ALERT:
            return show_update_alert(state,payload);
    default:
      return state;
  }
};

const show_create_alert = (state,payload) => {
    return {
        ...state,
        createAlert: payload
    };
}

const show_update_alert = (state,payload) => {
    return {
        ...state,
        updateAlert: payload
    };
}

export default NotificationReducer;