import React from 'react'
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import routes from './routes'
import PublicRoute from './Public'

const Routes = ({ assetPath }) => {

        return (
            <Router>
                <Switch>
                    {routes.map((route, i) => {
                        return <PublicRoute key={i} {...route} />
                    })}
                </Switch>
            </Router>
        );
}

export default Routes;