// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/user_profile",
    component: lazy(() => import("../../pages/UserAccess/UserProfile/index")),
  },
  {
    exact: true,
    path: "/user_profile/add",
    component: lazy(() => import("../../pages/UserAccess/UserProfile/create-edit")),
  },
  {
    exact: true,
    path: "/user_profile/edit/:id",
    component: lazy(() => import("../../pages/UserAccess/UserProfile/create-edit")),
  },
];

export default routes;
