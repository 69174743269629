import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/block',
    component: lazy(() => import('../pages/Block')),
  },
  {
    exact: true,
    path: '/block/add',
    component: lazy(() => import('../pages/Block/create')),
  },
  {
    exact: true,
    path: '/block/edit/:id',
    component: lazy(() => import('../pages/Block/edit')),
  },
];

export default routes;
