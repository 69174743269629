import { lazy } from "react";

const routes = [
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/fulfillment_center",
    component: lazy(() => import("../pages/Fulfillmentcenter")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/fulfillmentcenter/add",
    component: lazy(() => import("../pages/Fulfillmentcenter/create")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    exact: true,
    path: "/fulfillmentcenter/edit/:id",
    component: lazy(() => import("../pages/Fulfillmentcenter/create")),
  },
];

export default routes;
