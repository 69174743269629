// import lib
import { lazy } from 'react'

const routes =  [
    {
      path: '/manage_master',
      component: lazy(() => import('../pages/ManageMaster'))
    },
  ]

  export default routes
  