import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/village',
    component: lazy(() => import('../pages/Village')),
  },
  {
    exact: true,
    path: '/village/add',
    component: lazy(() => import('../pages/Village/create')),
  },
  {
    exact: true,
    path: '/village/edit/:id',
    component: lazy(() => import('../pages/Village/edit')),
  },
];

export default routes;
