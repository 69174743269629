import Http from "../Http";
import { BASE_URL, URL } from "../Helper";
import qs from "qs";
import {
  set_user_auth,
  authLogin,
  set_page_access_data,
} from "../store/actions/user";

export function signInUser(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(URL + "login", qs.stringify(data), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          dispatch(authLogin(res.data.access_token, res.data.refresh_token));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function renewSession() {
  console.log("In Renew Session Method")
  const body = {
    refreshToken: sessionStorage.getItem("refresh_token"),
  };

  console.log("IN RENEW SESSION METHOD")

  return (dispatch) => {
    new Promise((resolve, reject) => {
      delete Http.defaults.headers.common["Authorization"];
      console.log("Calling Refresh Token API")
      Http.post(URL + "token/refreshToken", body, { Authorization: "" })
        .then((res) => {
          const tokens = res?.data?.data;
          Http.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${tokens?.accessToken}`;
          dispatch(authLogin(tokens?.accessToken, tokens?.refreshToken));
        })
        .catch((err) => {
          return reject(err);
        });
    });
  };
}

export function getUserDetails(user_name) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(URL + "user/v1/" + user_name, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          dispatch(set_user_auth(res));

          // dispatch(set_states_value(res.data.data.states));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getPageAccessDetails(role) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(
        BASE_URL + `v1/pageDetail/viewInfo?appShortCode=AP&roles=${role}`,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
        .then((res) => {
          dispatch(set_page_access_data(res));

          // dispatch(set_states_value(res.data.data.states));
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}


export function getPageWiseAccessDetails(role, uniquePageId) {
  return Http.get(
    BASE_URL + `v1/pageDetail/viewInfo?appShortCode=AP&roles=${role}&frontEndPageUniqueId=${uniquePageId}`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  )
    .then((res) => {
      return res?.data?.data?.pageDetailsViewList[0];
    })
    .catch((err) => {
      return console.log(err);
    });
}

