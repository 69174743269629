import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/product',
    component: lazy(() => import('../pages/Product')),
  },
  {
    exact: true,
    path: '/product/add',
    component: lazy(() => import('../pages/Product/create')),
  },
  {
    exact: true,
    path: '/product/edit/:id',
    component: lazy(() => import('../pages/Product/edit')),
  },
];

export default routes;
