import { lazy } from "react";

const routes = [
    {
        exact: true,
        path: "/block-village-mapping",
        component: lazy(() => import("../pages/BlockVillageMapping")),
    },
];

export default routes;
