// import lib
import { lazy } from 'react'

const routes =  [
    {
      role:"ROLE_FULLFILLMENT_CENTER",
      exact:true,
      path: '/',
      component: lazy(() => import('../pages/SignIn'))
    },
  ]

  export default routes
  