// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/shippers",
    component: lazy(() => import("../pages/Shipper")),
  },
  {
    exact: true,
    path: "/shippers/editShipper/:shipperId",
    component: lazy(() => import("../pages/Shipper/editShipper")),
  },
];

export default routes;
