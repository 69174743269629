import axios from 'axios';
import store from './store';
import { renewSession } from './services/AuthService';

const codes = ["TOKEN-1012", "TOKEN-1014", "401"];

axios.interceptors.response.use(
  (response) => {
    if (codes.includes(response?.data?.apiStatus?.statusCode)) {
      store.dispatch({
        type: "SET_STATUS_CODE",
        payload: response?.data?.apiStatus?.statusCode
      })
    }
    else if (response?.data?.apiStatus?.statusCode === "TOKEN-1013") {
      store.dispatch(renewSession());
    }
    else {
      const errCode = response?.data?.apiStatus?.statusMessage === "Refresh token created successfully" ?
        store.getState().User.statusCode === "401" ?
          "401" :
          null : null;

      store.dispatch({
        type: "SET_STATUS_CODE",
        payload: errCode
      })
    }
    return response;
  },
  (error) => {
    console.warn(error);
    return Promise.reject(error);
  }
);
export default axios;
