import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/legalentity',
    component: lazy(() => import('../pages/LegalEntity')),
  },
  {
    exact: true,
    path: '/legalentity/add',
    component: lazy(() => import('../pages/LegalEntity/create')),
  },
  {
    exact: true,
    path: '/legalentity/edit/:id',
    component: lazy(() => import('../pages/LegalEntity/edit')),
  },
];

export default routes;
