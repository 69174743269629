// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/az_partner",
    component: lazy(() => import("../pages/AffiliateTemplate/index")),
  },
  {
    exact: true,
    path: "/az_partner/view/:affiliate",
    component: lazy(() => import("../pages/AffiliateTemplate/AffiliateView")),
  },
  {
    exact: true,
    path: "/az_partner/view/pending_finance/:affiliate",
    component: lazy(() => import("../pages/AffiliateTemplate/PaymentDetails")),
  },

  {
    exact: true,
    path: "/az_partner/view/pending_allocation/:affiliate",
    component: lazy(() =>
      import("../pages/AffiliateTemplate/AllocationDetails")
    ),
  },

  {
    exact: true,
    path: "/az_partner/view/topup_review/:affiliate",
    component: lazy(() => import("../pages/AffiliateTemplate/PaymentDetails")),
  },

  {
    exact: true,
    path: "/az_partner/view/affiliate-report/:affiliate/:type",
    component: lazy(() => import("../pages/AffiliateTemplate/AffiliateReport")),
  },
  {
    exact: true,
    path: "/az_partner/EditAffiliate/:affiliateId",
    component: lazy(() => import("../pages/AffiliateTemplate/EditAffiliate")),
  },
];

export default routes;
