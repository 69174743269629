export const SHOW_CREATE_ALERT = "SHOW_CREATE_ALERT";
export const SHOW_UPDATE_ALERT = "SHOW_UPDATE_ALERT";
export const AUTH_CHECK = "AUTH_CHECK";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD";
export const AUTH_USER = "AUTH_USER";
export const SET_CATALOG = "SET_CATALOG";
export const PAGE_ACCESS = "PAGE_ACCESS";
export const SET_INTERVAL_ID = "SET_INTERVAL_ID";
export const MANUALORDER_CUSTOMER_DETAILS = "MANUALORDER_CUSTOMER_DETAILS";
export const SET_DEAL = "SET_DEAL";
