import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/future_fulfillment",
    component: lazy(() => import("../pages/FutureFulfillment")),
  },
  {
    exact: true,
    path: "/enquiryItems/:enquiryId",
    component: lazy(() => import("../pages/FutureFulfillment/EnquiryItem")),
  },
  {
    exact: true,
    path: "/punchEnquiryOrder/:enquiryId",
    component: lazy(() => import("../pages/FutureFulfillment/PunchOrder")),
  },
];

export default routes;
