import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/seller_product_variant',
    component: lazy(() => import('../pages/SellerProductVariant')),
  },
  {
    exact: true,
    path: '/online_seller_product_variant',
    component: lazy(() => import('../pages/SellerProductVariant/online')),
  },
];

export default routes;
