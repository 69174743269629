import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/organizationtype',
    component: lazy(() => import('../pages/OrganizationType')),
  },
  {
    exact: true,
    path: '/organizationtype/add',
    component: lazy(() => import('../pages/OrganizationType/create')),
  },
  {
    exact: true,
    path: '/organizationtype/edit/:id',
    component: lazy(() => import('../pages/OrganizationType/edit')),
  },
];

export default routes;
