// import lib
import { lazy } from "react";

const routes = [
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    path: "/manage_order",
    component: lazy(() => import("../pages/Order/")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    path: "/fullfillment/orders",
    component: lazy(() => import("../pages/Fulfillmentcenter/orders")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    path: "/order_variants/:orderId",
    component: lazy(() => import("../pages/Order/OrderVariants")),
  },
  {
    role: "ROLE_FULLFILLMENT_CENTER",
    path: "/order_listing",
    component: lazy(() => import("../pages/Order/OrderListing")),
  },

  {
    exact: true,
    path: "/Order/promoOrderItems/:orderItemId",
    component: lazy(() => import("../pages/Order/PromoOrderItems")),
  },
  {
    exact: true,
    path: "/Order/editOrder/:orderItemId",
    component: lazy(() => import("../pages/Order/editOrder")),
  },
  {
    exact: true,
    path: "/Order/createOrder",
    component: lazy(() => import("../pages/Order/createOrder")),
  },
  {
    exact: true,
    path: "/Order/placeOrder",
    component: lazy(() => import("../pages/Order/PlaceOrder/placeOrder")),
  },

  {
    exact: true,
    path: "/off_platform_orders",
    component: lazy(() => import("../pages/Order/Off-PlatformOrders")),
  },
  {
    exact: true,
    path: "/instantOffSystemOrder",
    component: lazy(() =>
      import("../pages/Order/instantOffPlatformOrder/instantOffSystemOrder")
    ),
  },
  {
    exact: true,
    path: "/instantOffSystemOrder/:id",
    component: lazy(() =>
      import("../pages/Order/instantOffPlatformOrder/instantOffSystemOrder")
    ),
  },
];

export default routes;
