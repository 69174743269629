import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/setting',
    component: lazy(() => import('../pages/Setting')),
  },
  {
    exact: true,
    path: '/setting/edit/:id',
    component: lazy(() => import('../pages/Setting/edit')),
  },
];

export default routes;
