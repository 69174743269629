import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/create_combo",
    component: lazy(() =>
      import("../pages/ComboProduct/CreateCombo/CreateCombo")
    ),
  },
  {
    exact: true,
    path: "/edit_combo_pricing",
    component: lazy(() =>
      import("../pages/ComboProduct/OnlineComboDetails/EditComboPricing")
    ),
  },
  {
    exact: true,
    path: "/online_promotion_products",
    component: lazy(() =>
      import("../pages/ComboProduct/OnlineComboDetails/OnlinePromotionProducts")
    ),
  },

  {
    exact: true,
    path: "/promotion_products",
    component: lazy(() =>
      import("../pages/ComboProduct/CreateCombo/PromotionProducts")
    ),
  },
  {
    exact: true,
    path: "/add_pricing",
    component: lazy(() =>
      import("../pages/ComboProduct/CreateCombo/AddPricing")
    ),
  },
  {
    exact: true,
    path: "/edit_combo",
    component: lazy(() => import("../pages/ComboProduct/EditCombo/EditCombo")),
  },
];

export default routes;
