import { combineReducers } from "redux";
import NotificationReducer from "./NotificationReducer";
import User from "./User";
import Catalog from "./Catalog";
import ManualOrderReducer from "./manualOrder";
import DealReducer from "./dealReducer";

const RootReducer = combineReducers({
  NotificationReducer,
  User,
  Catalog,
  ManualOrderReducer,
  DealReducer,
});

export default RootReducer;
