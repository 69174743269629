// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/user_role",
    component: lazy(() => import("../../pages/UserAccess/UserRole/index")),
  },
  {
    exact: true,
    path: "/user_role/add",
    component: lazy(() => import("../../pages/UserAccess/UserRole/create")),
  },
  {
    exact: true,
    path: "/user_role/edit/:id",
    component: lazy(() => import("../../pages/UserAccess/UserRole/edit")),
  },
];

export default routes;
