import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/pincode',
    component: lazy(() => import('../pages/PinCode')),
  },
  {
    exact: true,
    path: '/pincode/add',
    component: lazy(() => import('../pages/PinCode/create')),
  },
  {
    exact: true,
    path: '/pincode/edit/:id',
    component: lazy(() => import('../pages/PinCode/edit')),
  },
];

export default routes;
