import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/district',
    component: lazy(() => import('../pages/District')),
  },
  {
    exact: true,
    path: '/district/add',
    component: lazy(() => import('../pages/District/create')),
  },
  {
    exact: true,
    path: '/district/edit/:id',
    component: lazy(() => import('../pages/District/edit')),
  },
];

export default routes;
