const initialState = {
  productDetails: {},
};

const Catalog = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case 'SET_CATALOG':
      return setCatalog(state, payload);
    default:
      return state;
  }
};

const setCatalog = (state, payload) => {
  return {
    ...state,
    productDetails: payload,
  };
};

export default Catalog;
