import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/hub',
    component: lazy(() => import('../pages/Hub')),
  },
  {
    exact: true,
    path: '/hub/add',
    component: lazy(() => import('../pages/Hub/create')),
  },
  {
    exact: true,
    path: '/hub/edit/:id',
    component: lazy(() => import('../pages/Hub/edit')),
  },
];

export default routes;
