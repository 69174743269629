// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/admin_seller",
    component: lazy(() => import("../pages/SellerTemplate")),
  },
  {
    exact: true,
    path: "/admin_seller/view/:sellerId",
    component: lazy(() => import("../pages/SellerTemplate/SellerView")),
  },
  {
    exact: true,
    path: "/admin_seller/editSeller/:sellerId",
    component: lazy(() => import("../pages/SellerTemplate/EditSeller")),
  },
];

export default routes;
