// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/role_action_mapping",
    component: lazy(() =>
      import("../../pages/UserAccess/RoleActionMapping/index")
    ),
  },
  {
    exact: true,
    path: "/role_action_mapping/add",
    component: lazy(() =>
      import("../../pages/UserAccess/RoleActionMapping/create")
    ),
  },
  {
    exact: true,
    path: "/role_action_mapping/edit/:id",
    component: lazy(() =>
      import("../../pages/UserAccess/RoleActionMapping/edit")
    ),
  },
];

export default routes;
