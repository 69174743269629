// import lib
import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/page_creation",
    component: lazy(() => import("../../pages/UserAccess/PageCreation/index")),
  },
  {
    exact: true,
    path: "/page_creation/add",
    component: lazy(() => import("../../pages/UserAccess/PageCreation/create")),
  },
  {
    exact: true,
    path: "/page_creation/edit/:id",
    component: lazy(() => import("../../pages/UserAccess/PageCreation/edit")),
  },
];

export default routes;
