import HTTP from "../../Http";

const initialState = {
  isLoggedIn: false,
  userDetails: {},
  statusCode: null,
  pageAccessDetails: {},
  intervalID: ""
};

const State = (
  state = initialState,
  { type, payload = null, accessToken, refreshToken }
) => {
  switch (type) {
    case "AUTH_LOGIN":
      return login(state, accessToken, refreshToken);
    case "AUTH_USER":
      return setUserDetails(state, payload);
    case "PAGE_ACCESS":
      return setPageAccessDetails(state, payload);
    case "AUTH_CHECK":
      return checkAuth(state);
    case "AUTH_LOGOUT":
      return logout(state);
    case "SET_STATUS_CODE":
      return {
        ...state,
        statusCode: payload,
      };
    case "SET_INTERVAL_ID":
      return {
        ...state,
        intervalID: payload
      }
    default:
      return state;
  }
};

function setUserDetails(state, payload) {
  return {
    ...state,
    userDetails: payload.data.data,
  };
}
function setPageAccessDetails(state, payload) {
  return {
    ...state,
    pageAccessDetails: payload.data.data,
  };
}
function login(state, accessToken, refreshToken) {
  sessionStorage.setItem("access_token", accessToken);
  sessionStorage.setItem("refresh_token", refreshToken);
  HTTP.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  checkAuth(state);
  return {
    ...state,
    isLoggedIn: true,
  };
}

function checkAuth(state) {
  const isAuthorized = !!sessionStorage.getItem("access_token");
  if (isAuthorized) {
    HTTP.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${sessionStorage.getItem("access_token")}`;
  }
  return {
    ...state,
    isLoggedIn: isAuthorized,
  };
}

function logout(state) {
  delete HTTP.defaults.headers.common["Authorization"];
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
  sessionStorage.clear();
  
  clearInterval(state.intervalID)

  return {
    ...state,
    isLoggedIn: false,
    intervalID: ""
  };
}

export const getAuth = (state) => state.auth.isLoggedIn;

export default State;
