import { lazy } from "react";

const routes = [
  {
    exact: true,
    path: "/orders-reports",
    component: lazy(() => import("../pages/Reports/ordersReport")),
  },
  {
    exact: true,
    path: "/sales-reports",
    component: lazy(() => import("../pages/Reports/salesReport")),
  },
  {
    exact: true,
    path: "/returns-reports",
    component: lazy(() => import("../pages/Reports/returnsReport")),
  },
  {
    exact: true,
    path: "/declinedByShipper-reports",
    component: lazy(() => import("../pages/Reports/declinedByShipper")),
  },
  {
    exact: true,
    path: "/commission-reports",
    component: lazy(() => import("../pages/Reports/commissionReport")),
  },
  {
    exact: true,
    path: "/remmittance-reports",
    component: lazy(() => import("../pages/Reports/remmittanceReport")),
  },
  {
    exact: true,
    path: "/partner-reports",
    component: lazy(() => import("../pages/Reports/partnerReport")),
  },
  {
    exact: true,
    path: "/deposite-approval",
    component: lazy(() => import("../pages/Reports/depositeApproval")),
  },
  {
    exact: true,
    path: "/collection-ledger",
    component: lazy(() => import("../pages/Reports/collectionLedger")),
  },
  {
    exact: true,
    path: "/collection-ledger-view/:affiliateId",
    component: lazy(() => import("../pages/Reports/collectionLedgerView")),
  },
  {
    exact: true,
    path: "/commission-ledger",
    component: lazy(() => import("../pages/Reports/commissionLedger")),
  },
  {
    exact: true,
    path: "/commission-ledger-view",
    component: lazy(() => import("../pages/Reports/commissionLedgerView")),
  },
  {
    exact: true,
    path: "/commission-payout",
    component: lazy(() => import("../pages/Reports/commissionPayout")),
  },
];

export default routes;
