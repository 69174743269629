import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/subdistrict',
    component: lazy(() => import('../pages/SubDistrict')),
  },
  {
    exact: true,
    path: '/subdistrict/add',
    component: lazy(() => import('../pages/SubDistrict/create')),
  },
  {
    exact: true,
    path: '/subdistrict/edit/:id',
    component: lazy(() => import('../pages/SubDistrict/edit')),
  },
];

export default routes;
