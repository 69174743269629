const initialState = {
  customerDetails: {},
};

const ManualOrderReducer = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case "MANUALORDER_CUSTOMER_DETAILS":
      // return customer_details(state, payload);
      return {
        ...state,
        customerDetails: payload,
      };
    default:
      return state;
  }
};
const customer_details = (state, payload) => {
  return {
    ...state,
    customerDetails: payload,
  };
};
export default ManualOrderReducer;
