import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/branch',
    component: lazy(() => import('../pages/Branch')),
  },
  {
    exact: true,
    path: '/branch/add',
    component: lazy(() => import('../pages/Branch/create')),
  },
  {
    exact: true,
    path: '/branch/edit/:id',
    component: lazy(() => import('../pages/Branch/edit')),
  },
];

export default routes;
