import { lazy } from 'react';

const routes = [
  {
    exact: true,
    path: '/state',
    component: lazy(() => import('../pages/State')),
  },
  {
    exact: true,
    path: '/state/add',
    component: lazy(() => import('../pages/State/create')),
  },
  {
    exact: true,
    path: '/state/edit/:id',
    component: lazy(() => import('../pages/State/edit')),
  },
];

export default routes;
